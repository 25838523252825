import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { HubCore } from "../../api";
import { ReactComponent as Building } from "../../Assets/Images/building.svg";
import { ReactComponent as BusinessMan } from "../../Assets/Images/businessman.svg";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import { ReactComponent as Map } from "../../Assets/Images/map.svg";
import ColumnFlex from "../../Components/ColumnFlex";
import GridComponent from "../../Components/GridComponent";
import LoaderComponent from "../../Components/LoaderComponent";
import NeuCard from "../../Components/NeuCard";
import NeuCardLink from "../../Components/NeuCardLink";
import RowFlex from "../../Components/RowFlex";
import Backups from "../OnlineDbBackup/Backups";

const BusinessView = () => {
    const [business, setBusiness] = useState(null);
    const [owners, setOwners] = useState(null);
    const [loading, setLoading] = useState(true);
    const [commands, setCommands] = useState([]);
    const [solutions, setSolutions] = useState(null);
    const [tiers, setTiers] = useState([]);
    const [selectedTierId, setSelectedTierId] = useState("");
    const [duration, setDuration] = useState(1);
    const [updateChannels, setUpdateChannels] = useState([]);
    const [updateChannel, setUpdateChannel] = useState(null);

    const isSuperStaff = localStorage.getItem("isSuper") === "true";

    let params = useParams();

    const fetchBusiness = () => {
        let url = `/businesses/${params.id}`;

        HubCore.get(url).then((response) => {
            setBusiness(response.data);
            fetchOwner(response.data.owners);
            fetchCommands();
            fetchSolutions();
        });
    };

    const fetchCommands = () => {
        HubCore.get(`https://commands.morabaaapps.com/api/v1/commands/ofbusiness/${params.id}`).then((response) => {
            setCommands(response.data);
        });
    };

    const fetchOwner = async (ownersIds) => {
        let fetchedOwners = [];
        for (let i = 0; i < ownersIds.length; i++) {
            let owner = await HubCore.get(`/owners/${ownersIds[i]}/`);
            fetchedOwners.push(owner.data);
        }
        setOwners(fetchedOwners);
        setLoading(false);
    };

    const editTitle = () => {
        let newTitle = prompt("enter new title");
        if (!newTitle) return;
        setBusiness({ ...business, title: newTitle });
        updateBusiness({ title: newTitle });
    };

    const editAddress = () => {
        let newAddress = prompt("enter new address");
        if (!newAddress) return;
        setBusiness({ ...business, address: newAddress });
        updateBusiness({ address: newAddress });
    };

    const updateBusiness = (data) => {
        HubCore.patch(`/businesses/${business.id}`, data);
    };

    const fetchSolutions = () => {
        HubCore.get(`/businesses/${params.id}/solutions`).then((response) => setSolutions(response.data));
    };

    const fetchTiers = (solutionId) => {
        HubCore.get(`/solutions/${solutionId}/tiers/`).then((response) => setTiers(response.data));
    };

    const deleteCommand = (commandId) => {
        HubCore.delete(`https://commands.morabaaapps.com/api/v1/commands/${commandId}`).then(() => setCommands(commands.filter((x) => x.id !== commandId)));
    };

    const fetchUpdateChannels = () => {
        HubCore.get(`/update-channels/`).then((response) => setUpdateChannels(response.data));
    };

    const fetchUpdateChannel = () => {
        if (!business || !updateChannels) return;
        HubCore.get(`/update-channels/businesses/${business.id}`).then((response) => setUpdateChannel(response.data));
    };

    const updateBusinessUpdateChannel = (channelKey) => {
        if (!!channelKey) HubCore.post(`/update-channels/businesses/${business.id}`, { channelKey: channelKey });
        else HubCore.delete(`/update-channels/businesses/${business.id}`);
    };

    const subscribe = () => {
        if (!window.confirm("Give them a free subscription?")) return;
        HubCore.post(`/subscriptions/staffsub`, {
            business_id: business.id,
            tier_id: selectedTierId,
            duration,
        }).then(() => {
            alert("Done");
            setTiers([]);
            setSelectedTierId("");
            fetchSolutions();
        });
    };

    const deleteBusiness = () => {
        if (window.confirm("هل انتَ متأكد؟ لا يمكن التراجع"))
            HubCore.delete(`https://hubcore.morabaaapps.com/api/v1/businesses/${business.id}`)
                .then(() => alert("سيتم مسح الشركة"))
                .catch(() => alert("غير مسموح لك"));
    };

    const unsubscribe = (e, subscriptionId) => {
        e.preventDefault();
        e.stopPropagation();
        if (!subscriptionId || !window.confirm("هل أنتَ متأكد؟")) return false;
        HubCore.delete(`/subscriptions/${subscriptionId}`).then(() => {
            alert("تم");
            fetchSolutions();
        });
        return false;
    };

    useEffect(() => {
        fetchUpdateChannel();
    }, [business, updateChannels]);

    useEffect(() => {
        fetchBusiness();
        fetchUpdateChannels();
    }, []);

    return loading ? (
        <LoaderComponent />
    ) : (
        <ColumnFlex>
            <p className="font-bold mb-4">
                <span className="text-3xl text-gray-800">{business.title}</span>
                <Edit className="inline mx-4 cursor-pointer" width={28} onClick={editTitle} />
                <br />
            </p>
            <div className="flex flex-row mb-2 items-center">
                <Map width={28} />
                <p className="font-bold text-xl mx-4 text-gray-600 text-center">{business.address}</p>
                <Edit className="inline mx-4 cursor-pointer" width={28} onClick={editAddress} />
            </div>
            <div className="flex flex-row mb-2 items-center">
                <Building width={28} />
                <p className="font-bold text-xl mx-4 text-gray-600 text-center">{business.businessCategory.title}</p>
            </div>
            {owners && (
                <div className="flex flex-row mb-4 items-center">
                    <BusinessMan width={28} />
                    {owners.map((owner) => (
                        <Link key={owner.id} className="font-bold text-xl mx-4 text-gray-600 text-center" to={`/owners/${owner.id}/`}>
                            {owner.firstName} {owner.lastName}
                        </Link>
                    ))}
                </div>
            )}
            {isSuperStaff && (
                <RowFlex>
                    <button className="mr-2 px-4 py-2 rounded bg-red-700 text-gray-50 font-bold" onClick={() => deleteBusiness()}>
                        مسح الشركة
                    </button>
                </RowFlex>
            )}
            <RowFlex className="mt-4">
                {business.logoPath && (
                    <img alt="logo" src={`https://hubcore.morabaaapps.com${business.logoPath}`} className="ml-2" style={{ height: 120, width: 120 }} />
                )}
                {business.bannerPath && <img alt="banner" src={`https://hubcore.morabaaapps.com${business.bannerPath}`} style={{ height: 120, flex: 1 }} />}
            </RowFlex>
            <RowFlex className="mt-4 gap-4">
                <select
                    className="px-4 py-2 rounded"
                    value={updateChannel ? updateChannel.key : ""}
                    onChange={(e) => {
                        updateBusinessUpdateChannel(e.target.value);
                        setUpdateChannel(updateChannels.find((x) => x.key === e.target.value));
                    }}>
                    <option value="">اختر قناة التحديث</option>
                    {updateChannels.map((x) => (
                        <option key={x.id} value={x.key}>
                            {x.key}
                        </option>
                    ))}
                </select>
                <p className="self-center">اخر قناة مطلوبة: {business.latestUpdateChannelRequsted ?? "-"}</p>
            </RowFlex>

            <SubscribeToBundle businessId={business.id} />
            <p>الإشتراك الحالي: {business.bundle ?? "free"}</p>

            {solutions && solutions.subscribed && (
                <ColumnFlex>
                    <p className="font-bold text-gray-700 text-2xl mt-4 mb-2">الخدمات المشترك بها</p>
                    <GridComponent>
                        {solutions.subscribed.map((x) => (
                            <NeuCard key={x.solution.id} onClick={() => fetchTiers(x.solution.id)}>
                                <ColumnFlex>
                                    <p className="p-4">
                                        <span className="lg:text-xl font-bold">{x.solution.title}</span>
                                        <br />
                                        {x.tier.key}
                                        <br />
                                        {new Date(x.subscription.startedAt).toLocaleDateString()} - {new Date(x.subscription.endsAt).toLocaleDateString()}
                                    </p>
                                    <button
                                        type="button"
                                        className="px-4 py-2 rounded bg-red-700 text-gray-50 font-bold"
                                        onClick={(e) => unsubscribe(e, x.subscription.id)}>
                                        حذف
                                    </button>
                                </ColumnFlex>
                            </NeuCard>
                        ))}
                    </GridComponent>
                </ColumnFlex>
            )}
            {solutions && solutions.others && (
                <ColumnFlex>
                    <p className="font-bold text-gray-700 text-2xl mt-4 mb-2">الخدمات غير المشترك بها</p>
                    <GridComponent lgCols="3">
                        {solutions.others.map((solution) => (
                            <NeuCard key={solution.id} onClick={() => fetchTiers(solution.id)}>
                                <ColumnFlex>
                                    <p className="text-xs lg:text-lg xl:text-xl p-4 font-bold">{solution.title}</p>
                                </ColumnFlex>
                            </NeuCard>
                        ))}
                    </GridComponent>
                </ColumnFlex>
            )}
            {tiers && tiers.length > 0 && (
                <ColumnFlex>
                    <GridComponent>
                        {tiers.map((x) => (
                            <NeuCard key={x.tier.id} onClick={() => setSelectedTierId(x.tier.id)}>
                                <ColumnFlex className={`${x.tier.id === selectedTierId ? "border-b-4 rounded-lg border-green-400" : ""}`}>
                                    <p className="text-xl p-4">
                                        <span className="font-bold">{x.tier.title}</span>
                                        <br />${x.tier.monthlyPrice}
                                        <br />
                                        {x.tier.key}
                                        <br />
                                        {x.limitations.map((y) => (
                                            <span key={y.id}>
                                                {y.description}: {y.decimalValue}
                                                <br />
                                            </span>
                                        ))}
                                    </p>
                                </ColumnFlex>
                            </NeuCard>
                        ))}
                    </GridComponent>
                    <p>عدد الأشهر</p>
                    <input
                        className="px-4 py-2 rounded mt-2"
                        type="number"
                        min="1"
                        max="12"
                        value={duration}
                        defaultValue={1}
                        onChange={(e) => setDuration(parseInt(e.target.value))}
                    />
                    <button className="px-4 py-2 mt-2 rounded bg-green-500 hover:bg-green-600 text-gray-50 font-bold text-xl" onClick={subscribe}>
                        تفعيل
                    </button>
                </ColumnFlex>
            )}
            {commands && (
                <ColumnFlex>
                    <p className="font-bold text-gray-700 text-2xl mt-4 mb-2">الأوامر المعلقة</p>
                    <GridComponent>
                        <NeuCardLink link={`/businesses/${business.id}/newcommand`} data={business}>
                            <ColumnFlex className="h-full justify-center">
                                <p className="font-bold text-center text-lg py-4">أمر جديد</p>
                            </ColumnFlex>
                        </NeuCardLink>
                        {commands.map((command) => (
                            <NeuCard>
                                <ColumnFlex>
                                    <p className="text-xl p-4">
                                        <span className="font-bold">{command.commandKey}</span>
                                        <br />
                                        <span className="font-semibold">{new Date(command.createdAt).toLocaleString()}</span>
                                    </p>
                                    <button className="px-4 py-2 rounded bg-red-700 text-gray-100 mx-2 mb-2 self-end" onClick={() => deleteCommand(command.id)}>
                                        حذف
                                    </button>
                                </ColumnFlex>
                            </NeuCard>
                        ))}
                    </GridComponent>
                </ColumnFlex>
            )}
            {business && solutions && solutions.subscribed && solutions.subscribed.some((x) => x.solution.packageId === "com.morabaa.onlinedbbackup") && (
                <>
                    <p className="font-bold text-gray-700 text-2xl mt-4 mb-2">النسخ الاحتياطية</p>
                    <Backups businessId={business.id} />
                </>
            )}
            {solutions && solutions.subscribed && solutions.subscribed.some((x) => x.solution.packageId === "com.morabaa.onlinelitebackup") && (
                <div className="flex flex-row gap-4">
                    <a
                        rel="noreferrer"
                        href={`https://backup-lite.morabaaapps.com/backup/${business.id}/xls`}
                        target="_blank"
                        className="px-4 py-2 rounded bg-green-500 hover:bg-green-600 text-gray-50 font-bold text-xl">
                        تصدير البيانات
                    </a>
                    <a
                        rel="noreferrer"
                        href={`https://backup-lite.morabaaapps.com/backup/${business.id}/items/xls`}
                        target="_blank"
                        className="px-4 py-2 rounded bg-green-500 hover:bg-green-600 text-gray-50 font-bold text-xl">
                        تصدير المواد
                    </a>
                    <a
                        rel="noreferrer"
                        href={`https://backup-lite.morabaaapps.com/backup/${business.id}/accounts/xls`}
                        target="_blank"
                        className="px-4 py-2 rounded bg-green-500 hover:bg-green-600 text-gray-50 font-bold text-xl">
                        تصدير الحسابات
                    </a>
                </div>
            )}
        </ColumnFlex>
    );
};

export default BusinessView;

const SubscribeToBundle = ({ businessId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [link, setLink] = useState("");

    const subscribeToBundle = (bundle, paidInCash) => {
        if (isLoading) return;
        setIsLoading(true);
        HubCore.post(!!paidInCash ? "/subscriptions/subscribe-to-bundle" : "/subscriptions", {
            businessId,
            bundle,
        })
            .then((r) => {
                if (!!paidInCash) {
                    window.location.reload();
                } else {
                    navigator.clipboard.writeText(r.headers["Payment-Form-Url"]);
                    alert("تم الإشتراك بنجاح، تم نسخ الرابط للدفع");
                    setLink(r.headers["Payment-Form-Url"]);
                    setIsLoading(false);
                }
            })
            .catch((e) => {
                alert("حدث خطأ");
                console.error(e);
                setIsLoading(false);
            });
    };

    return (
        <div className="flex flex-col gap-4 my-4">
            <div className="flex flex-row gap-4">
                <p>كاش</p>
                <button
                    disabled={isLoading}
                    type="button"
                    className="hover:bg-gray-700 bg-gray-600 px-4 py-2 rounded-lg text-gray-100 font-bold cursor-pointer"
                    onClick={() => {
                        subscribeToBundle("lite", true);
                    }}>
                    إشتراك لايت
                </button>
                <button
                    disabled={isLoading}
                    type="button"
                    className="hover:bg-green-700 bg-green-600 px-4 py-2 rounded-lg text-gray-100 font-bold cursor-pointer"
                    onClick={() => {
                        subscribeToBundle("plus", true);
                    }}>
                    إشتراك بلص
                </button>
                <button
                    disabled={isLoading}
                    type="button"
                    className="hover:bg-indigo-700 bg-indigo-600 px-4 py-2 rounded-lg text-gray-100 font-bold cursor-pointer"
                    onClick={() => {
                        subscribeToBundle("pro", true);
                    }}>
                    إشتراك برو
                </button>
            </div>
            <div className="flex flex-row gap-4">
                <p>بطاقة دفع</p>
                <button
                    disabled={isLoading}
                    type="button"
                    className="hover:bg-gray-700 bg-gray-600 px-4 py-2 rounded-lg text-gray-100 font-bold cursor-pointer"
                    onClick={() => {
                        subscribeToBundle("lite", false);
                    }}>
                    إشتراك لايت
                </button>
                <button
                    disabled={isLoading}
                    type="button"
                    className="hover:bg-green-700 bg-green-600 px-4 py-2 rounded-lg text-gray-100 font-bold cursor-pointer"
                    onClick={() => {
                        subscribeToBundle("plus", false);
                    }}>
                    إشتراك بلص
                </button>
                <button
                    disabled={isLoading}
                    type="button"
                    className="hover:bg-indigo-700 bg-indigo-600 px-4 py-2 rounded-lg text-gray-100 font-bold cursor-pointer"
                    onClick={() => {
                        subscribeToBundle("pro", false);
                    }}>
                    إشتراك برو
                </button>
                {!!link && (
                    <a
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:bg-blue-700 bg-blue-600 px-4 py-2 rounded-lg text-gray-100 font-bold cursor-pointer">
                        الدفع
                    </a>
                )}
            </div>
        </div>
    );
};
