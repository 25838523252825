import moment from "moment";
import { useEffect, useState } from "react";

let timeout = null;

const ReportDownloadView = () => {
    const [dtGte, setDtGte] = useState(moment().startOf("month").format("YYYY-MM-DD hh:mm"));
    const [dtLt, setDtLt] = useState(moment().endOf("month").add(1, "days").startOf("day").format("YYYY-MM-DD hh:mm"));

    const [subscriptions, setSubscriptions] = useState([]);

    const downloadReportBlob = () => {
        const url = `https://hubcore.morabaaapps.com/api/v1/subscriptions/report/?dtGte=${new Date(dtGte).toISOString()}&dtLt=${new Date(dtLt).toISOString()}`;
        fetch(url, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "text/csv",
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "report.csv");
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
    };

    const refreshSubscriptions = () => {
        const url = `https://hubcore.morabaaapps.com/api/v1/subscriptions/report/?dtGte=${new Date(dtGte).toISOString()}&dtLt=${new Date(dtLt).toISOString()}`;
        fetch(url, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => setSubscriptions(data));
    };

    useEffect(() => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            refreshSubscriptions();
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dtGte, dtLt]);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-2 items-center">
                <input type="datetime-local" value={dtGte} onChange={(e) => setDtGte(e.target.value)} className="px-4 py-2 border border-gray-600 rounded" />
                <input type="datetime-local" value={dtLt} onChange={(e) => setDtLt(e.target.value)} className="px-4 py-2 border border-gray-600 rounded" />
                <button onClick={downloadReportBlob} className="px-4 py-2 border border-gray-600 rounded hover:bg-gray-400 cursor-pointer">
                    تحميل
                </button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th className="text-right border border-gray-400 px-2 py-1">ت</th>
                        <th className="text-right border border-gray-400 px-2 py-1">الزبون</th>
                        <th className="text-right border border-gray-400 px-2 py-1">المستخدم</th>
                        <th className="text-right border border-gray-400 px-2 py-1">السعر الكلي</th>
                        <th className="text-right border border-gray-400 px-2 py-1">الخدمة</th>
                        <th className="text-right border border-gray-400 px-2 py-1">الدرجة</th>
                        <th className="text-right border border-gray-400 px-2 py-1">سعر الشهر</th>
                        <th className="text-right border border-gray-400 px-2 py-1">تاريخ الإشتراك</th>
                        <th className="text-right border border-gray-400 px-2 py-1">تاريخ الإنتهاء</th>
                        <th className="text-right border border-gray-400 px-2 py-1">عدد الأشهر</th>
                    </tr>
                </thead>
                <tbody>
                    {subscriptions.map((x, i) => (
                        <tr key={i}>
                            <td className="text-right border border-gray-400 px-2 py-1">{i + 1}</td>
                            <td className="text-right border border-gray-400 px-2 py-1">{x.businessName}</td>
                            <td className="text-right border border-gray-400 px-2 py-1">{x.userName}</td>
                            <td className="text-right border border-gray-400 px-2 py-1">{x.totalPrice.toLocaleString("en-US")}</td>
                            <td className="text-right border border-gray-400 px-2 py-1">{x.solutionName}</td>
                            <td className="text-right border border-gray-400 px-2 py-1">{x.tierName}</td>
                            <td className="text-right border border-gray-400 px-2 py-1">{x.monthlyPrice.toLocaleString("en-US")}</td>
                            <td dir="ltr" className="text-right border border-gray-400 px-2 py-1">
                                {moment(x.startAt).format("YYYY-MM-DD HH:mm")}
                            </td>
                            <td dir="ltr" className="text-right border border-gray-400 px-2 py-1">
                                {moment(x.endAt).format("YYYY-MM-DD HH:mm")}
                            </td>
                            <td className="text-right border border-gray-400 px-2 py-1">{x.durationInMonths.toLocaleString("en-US")}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ReportDownloadView;
