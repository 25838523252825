import React from "react";
import { HubCore } from "../api";
import RowFlex from "./RowFlex";
import Moment from "react-moment";
import LinkCard from "./LinkCard";
import { Link } from "react-router-dom";
import MaterialIcon from "./MaterialIcon";

const OwnerCard = ({ ownerDto, ...props }) => {
    const owner = ownerDto.owner;
    const businesses = ownerDto.businesses;
    const subscribedTo = ownerDto.subscribedTo;

    const getFormattedCredits = () => {
        let fomratter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        });
        return fomratter.format(owner.credits);
    };

    const getDate = (date) => <Moment fromNow>{date}</Moment>;

    const loginAs = () => {
        if (owner.id === "8e2ab4f2-f3ab-466e-98ac-cdee0db3688b") {
            return;
        }
        HubCore.get(`/owners/loginas/${owner.id}`).then((response) => {
            let link = `https://my.morabaaapps.com/alreadyloggedin/${response.data}`;
            window.open(link, "_blank");
        });
    };

    return (
        <div className="justify-around bg-white py-2 rounded-md shadow-lg" key={owner.id}>
            <div className="p-2 flex justify-between">
                <Link to={`/owners/${owner.id}`}>
                    <p className="font-bold py-2">
                        {owner.firstName} {owner.lastName}
                    </p>
                </Link>
                <RowFlex className="py-2 rounded-md ">
                    <p className="ml-2 font-semibold text-lg text-green-600">{getFormattedCredits()}</p>
                    <Link data={owner} className="pt-1" to={`/addaccountbalanceview/${owner.id}/`}>
                        <MaterialIcon icon="add_circle" className="text-green-400" />
                    </Link>
                </RowFlex>
            </div>
            <p className="font-bold p-1 pr-2">الخدمات المشترك بها</p>
            <RowFlex className="overflow-x-auto">
                {Object.keys(subscribedTo).map((solution) => (
                    <Link to={`/subs/${solution}/${owner.id}/`} className="min-w-max" key={solution}>
                        <button className="bg-blue-200 p-1 rounded-md m-1">{subscribedTo[solution]}</button>
                    </Link>
                ))}
            </RowFlex>
            <p className="font-bold p-1 pr-2">الشركات</p>
            <div className="p-2 flex justify-start">
                {Object.keys(businesses).map((business) => (
                    <Link to={`/businesses/${business}/`} key={business}>
                        <button className="bg-blue-200 p-1 rounded-md m-1">{businesses[business]}</button>
                    </Link>
                ))}
            </div>
            <div className="p-2 flex justify-start">
                <LinkCard to={`${owner.id}/tickets`} className="bg-blue-200 p-1 rounded-md m-1">
                    التذاكر
                </LinkCard>
                <LinkCard to={`${owner.id}/balance`} className="bg-blue-200 p-1 rounded-md m-1">
                    الحركات المالية
                </LinkCard>
            </div>
            <div onClick={() => props.ChangeOwnerAddress(owner)} className="font-bold cursor-pointer p-1 pr-2">
                العنوان: <p className="inline mr-1 ml-3 px-2 rounded border border-gray-500">{owner.address}</p>
            </div>
            <p className="font-bold p-1 pr-2">الهاتف: {owner.phoneNumber}</p>
            <p className="font-bold p-1 pr-2">تاريخ التسجيل: {getDate(owner.createdAt)}</p>
            {owner.id !== "8e2ab4f2-f3ab-466e-98ac-cdee0db3688b" && (
                <button className="px-4 py-2 rounded cursor-pointer bg-gray-700 text-gray-100 font-bold text-xs mx-2 mt-4" onClick={loginAs}>
                    Login As
                </button>
            )}
        </div>
    );
};

export default OwnerCard;
